@use 'sass:math';

@function p-rem($pxValue) {
  @return math.div($pxValue, 16px) * 1rem;
}


@mixin app-page-max-w {
  max-width: $app-max-width;
}
