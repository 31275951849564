$custom-colors: (
  'red',
  'purple',
  'blue',
  'green',
  'yellow',
  'orange',
  'accent',
  'white',
  'highlightorange',
  'orangebutton'
);
$app-green: #437d76;
$app-green-rgb: rgba($app-green, 1);
$app-green-alt: #00d2a3;
$app-green-alt-rgb: 0, 210, 163;
$app-green-alt-rgb-06: rgba($app-green-alt, 0.6);
$app-green-light: #20cc99;
$app-green-light-rgb: rgba($app-green-light, 1);
$app-brown: #989184;
$app-brown-rgb: rgba($app-brown, 1);
$app-orange: #fd6e41;
$app-orange-rgb: rgba($app-orange, 1);
$app-dark-light: #151d20;
$app-dark-light-rgb: rgba($app-dark-light, 1);
$app-grey-menu: #f2f2f4;
$app-grey-menu-rgb: rgba($app-grey-menu, 1);

:root {
  /** primary **/
  --ion-color-primary: #437d76;
  --ion-color-primary-rgb: 67, 125, 118;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3b6e68;
  --ion-color-primary-tint: #568a84;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #ffffff;
  --ion-color-white-contrast-rgb: 255, 255, 255;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  /* accent slot color */
  --ion-color-accent: #54c5a3;
  --ion-color-accent-rgb: 84, 197, 163;
  --ion-color-accent-contrast: #{$app-green};
  --ion-color-accent-contrast-rgb: #{$app-green-rgb};
  --ion-color-accent-shade: #4aad8f;
  --ion-color-accent-tint: #65cbac;

  /* accent green default color */
  --ion-color-accentdefault: #54c5a3;
  --ion-color-accentdefault-rgb: 84, 197, 163;
  --ion-color-accentdefault-contrast: #{$app-green};
  --ion-color-accentdefault-contrast-rgb: #{$app-green-rgb};
  --ion-color-accentdefault-shade: #4aad8f;
  --ion-color-accentdefault-tint: #65cbac;

  /* comcolors user colors */
  --ion-color-red: #cc0001;
  --ion-color-red-rgb: 204, 0, 1;
  --ion-color-red-contrast: #{$app-green};
  --ion-color-red-contrast-rgb: #{$app-green-rgb};
  --ion-color-red-shade: #b40001;
  --ion-color-red-tint: #d11a1a;

  /* comcolors purple */
  --ion-color-purple: #704a97;
  --ion-color-purple-rgb: 112, 74, 151;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #634185;
  --ion-color-purple-tint: #7e5ca1;

  /* comcolors blue */
  --ion-color-blue: #006496;
  --ion-color-blue-rgb: 0, 100, 150;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #005884;
  --ion-color-blue-tint: #1a74a1;

  /* comcolors green */
  --ion-color-green: #669934;
  --ion-color-green-rgb: 102, 153, 52;
  --ion-color-green-contrast: #{$app-green};
  --ion-color-green-contrast-rgb: #{$app-green-rgb};
  --ion-color-green-shade: #5a872e;
  --ion-color-green-tint: #75a348;

  /* comcolors yellow */
  --ion-color-yellow: #f1a81e;
  --ion-color-yellow-rgb: 241, 168, 30;
  --ion-color-yellow-contrast: #{$app-green};
  --ion-color-yellow-contrast-rgb: #{$app-green-rgb};
  --ion-color-yellow-shade: #d4941a;
  --ion-color-yellow-tint: #f2b135;

  /* comcolors orange */
  --ion-color-orange: #f0611b;
  --ion-color-orange-rgb: 240, 97, 27;
  --ion-color-orange-contrast: #{$app-green};
  --ion-color-orange-contrast-rgb: #{$app-green-rgb};
  --ion-color-orange-shade: #d35518;
  --ion-color-orange-tint: #f27132;

  /* comcolors orange button */
  --ion-color-orangebutton: #ff6600; // ?
  --ion-color-orangebutton-rgb: 255, 102, 0; // default color
  --ion-color-orangebutton-contrast: white; // text color
  --ion-color-orangebutton-contrast-rgb: #ff6600; // ?
  --ion-color-orangebutton-shade: #f56a0e; // on click
  --ion-color-orangebutton-tint: #cc5d12; // on hover

  /* highlight orange */
  --ion-color-highlightorange: #fd6e41;
  --ion-color-highlightorange-rgb: 253, 110, 65;
  --ion-color-highlightorange-contrast: #fd6e41;
  --ion-color-highlightorange-contrast-rgb: 0, 0, 0;
  --ion-color-highlightorange-shade: #df6139;
  --ion-color-highlightorange-tint: #fd7d54;

  /* highlight */
  --ion-color-highlight: #{$app-orange};
  --ion-color-highlight-rgb: #{$app-orange-rgb};

  /* app color */
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  /* app text color */
  --ion-text-color: #{$app-green};
  --ion-text-color-rgb: #{$app-green-rgb};
  --ion-text-color-secondary: #{$app-brown};
  --ion-text-color-secondary-rgb: #{$app-brown-rgb};
  --ion-white-to-black: white;
  --ion-black-to-white: black;

  /* highlights */
  --highlight-color-invalid: #eb5757;

  /* app text color steps */
  --ion-color-step-50: #f6f9f8;
  --ion-color-step-100: #ecf2f1;
  --ion-color-step-150: #e3ecea;
  --ion-color-step-200: #d9e5e4;
  --ion-color-step-250: #d0dfdd;
  --ion-color-step-300: #c7d8d6;
  --ion-color-step-350: #bdd2cf;
  --ion-color-step-400: #b4cbc8;
  --ion-color-step-450: #aac5c1;
  --ion-color-step-500: #a1bebb;
  --ion-color-step-550: #98b8b4;
  --ion-color-step-600: #8eb1ad;
  --ion-color-step-650: #85aba6;
  --ion-color-step-700: #7ba49f;
  --ion-color-step-750: #729e98;
  --ion-color-step-800: #699791;
  --ion-color-step-850: #5f918b;
  --ion-color-step-900: #568a84;
  --ion-color-step-950: #4c847d;

  /* main tab menu color */
  --comcolors-tab-accent: #{$app-orange};
  --comcolors-tab-color: #{$app-green};
  --comcolors-tab-active-profile-question-color: #{$app-orange};
  --comcolors-tab-active-profile-question-circles-color: #{$app-orange};
  --comcolors-tab-more-menu-bg: #{$app-grey-menu};

  /* profile unknown */
  --comcolors-profile-unknown-question-color: #{$app-green};
  --comcolors-profile-unknown-question-circles-color: #{$app-brown};

  /* main menu color */
  --comcolors-main-menu: #{$app-brown};

  /* modal */
  --comcolors-modal-background: #{$app-green};

  /* contact */
  --comcolors-contact-background: #{$app-green};
  --comcolors-contact-stroke: #ffffff;

  /* contextual menu */
  --comcolors-contexual-menu-background: white;

  /* toggle */
  --comcolors-toggle-background: #{rgba($app-green-light, 0.15)};
  --comcolors-toggle-thumb-background: var(--ion-color-orange);

  /* background */
  --comcolors-bg-green-alt: #{$app-green-alt-rgb};
}

/* custom dark theme */
[data-theme='dark'] {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-text-color-secondary: #ffffff;
    --ion-text-color-secondary-rgb: 255, 255, 255;
    --ion-white-to-black: black;
    --ion-black-to-white: white;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;

    /* main tab menu color */
    --comcolors-tab-accent: var(--ion-color-accentdefault);
    --comcolors-tab-color: white;
    --comcolors-tab-active-profile-question-color: var(--ion-color-accentdefault);
    --comcolors-tab-active-profile-question-circles-color: #{$app-green-alt-rgb-06};
    --comcolors-tab-more-menu-bg: #{$app-dark-light};

    /* profile unknown */
    --comcolors-profile-unknown-question-color: white;
    --comcolors-profile-unknown-question-circles-color: white;

    /* main menu color */
    --comcolors-main-menu: white;

    /* modal */
    --comcolors-modal-background: #{$app-dark-light};

    /* contextual menu */
    --comcolors-contexual-menu-background: #{$app-dark-light};

    /* toggle */
    --comcolors-toggle-background: #{rgba(white, 0.15)};
    --comcolors-toggle-thumb-background: var(--ion-text-color);

    /* background */
    --comcolors-bg-green-alt: #{$app-green-alt-rgb};

    /* contact */
    --comcolors-contact-background: #{$app-green};
    --comcolors-contact-stroke: #54c5a3;
  }
}

/* write comcolors' colors classes */
@each $color in $custom-colors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color}) !important;
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
    --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
    --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
  }
}
