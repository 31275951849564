@import 'fonts';
@import 'variables';
@import 'functions';
@import 'colors';
@import 'reset';

* {
  -ms-overflow-style: none;
  scrollbar-width: 9px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--ion-text-color-secondary);
    border: transparent;
  }
}
/* stylelint-disable */
ion-backdrop {
  opacity: 0.15 !important;
}

ion-router-link {
  color: var(--ion-text-color-secondary);
  text-decoration: underline;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

ion-button.clear {
  color: var(--ion-color-accent);
  height: unset;
  --background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --border-radius: 0;
  --border-color: transparent;
  --border-style: solid;
  --border-width: 0;
  --box-shadow: none;
  --ripple-color: transparent;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;

  .icon {
    color: var(--ion-color-accent);
    margin-right: p-rem(8px);
  }

  span.label {
    font-weight: $font-weight-normal;
  }
}

.page-inner-container {
  padding-bottom: 4rem !important;
}

ion-tab-button {
  max-width: $tab-button-max-width;
}

.app-page-max-w {
  @include app-page-max-w;
}

ion-header,
.app-page {
  @include app-page-max-w;
  margin: 0 auto;
}

ion-content.page-layout-questionnaire,
ion-content.page-layout-shop {
  --keyboard-offset: 0 !important;
}

ion-content.page-layout-shop {
  --overflow: hidden;
}

.schema-tooltip {
  padding: 20px !important;
  opacity: 1 !important;
  border-radius: 10px !important;
}

.tooltip-wrapper {
  color: white;
  text-align: left;
}

.tooltip-wrapper ul {
  margin-left: 30px;
  margin-bottom: 14px;
}
/* stylelint-enable */

.swiper-slide {
  overflow-y: auto;
}
