h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
td {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  text-decoration: none;
  color: currentcolor;
  box-sizing: border-box;
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* svg rendering issue on chrome: trying */
svg path,
svg circle,
svg line,
svg polygon,
svg ellipse,
svg polyline,
svg rect {
  shape-rendering: geometricPrecision;
}
