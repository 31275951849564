/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* font-family */
  --ion-font-family: Roboto, sans-serif;

  /* swiper */
  --swiper-pagination-bullet-width: 16px;
  --swiper-pagination-bullet-height: 16px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

$tab-button-max-width: 192px;
$app-max-width: calc($tab-button-max-width * 5);
