$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

/* THIN */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $font-weight-thin;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-thin.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: $font-weight-thin;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-thin-italic.woff') format('woff');
}

/* LIGHT */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $font-weight-light;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-light.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: $font-weight-light;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-light-italic.woff') format('woff');
}

/* REGULAR */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $font-weight-normal;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: $font-weight-normal;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-italic.woff') format('woff');
}

/* MEDIUM */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $font-weight-medium;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-medium.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: $font-weight-medium;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-medium-italic.woff') format('woff');
}

/* BOLD */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-bold.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: $font-weight-bold;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-bold-italic.woff') format('woff');
}

/* BLACK */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $font-weight-black;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-black.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: $font-weight-black;
  font-display: swap;
  src: url('../assets/fonts/roboto/roboto-black-italic.woff') format('woff');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
